loader-inline-block {
  display: inline-block;
}

loader-text-margin {
  margin-left: 5px;
}

loader-float-none {
  float: none; 
}
