.NewLeague form {
    padding-bottom: 15px;
  }
  
  .NewLeague form textarea {
    height: 300px;
    font-size: 24px;
  }

  .groupme-button{
    margin-bottom: 10px;
  }

  .GroupMeAuth{
    text-align: center;
  }

  .GroupMeAuth h4{
    margin-top: 50px;
    margin-bottom: 10px;
  }

  form{
    margin-top: 50px;
  }

  .league-id-pic{
    width: 50%;
    height: auto;
    margin-bottom: 10px;
  }

  .league-id-tooltip-div{
    font-size: 9pt;
  }

  .cookies-tooltip-div{
    font-size: 9pt;
    text-align: left;
  }

  .cookies-help{
    margin-bottom: 16px;
  }

.cookieDefaultToggleStyle:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
  transform: rotate(90deg) ;
  transition: all linear 0.25s;
  float: right;
}   

.cookieToggleStyle:after {
  transform: rotate(0deg) ;
}

.vis-help{
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
}

  .league-id-button{
    margin-top: 5px;
  }

  .tzPicker{
    width:100%;
    display: block;
    font-size: 16px;
    font-family: inherit;
    background-clip: padding-box;
    font-weight: 400;
    line-height: 1.5;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .timezone-picker{
    font-size: 16px;
    font-family: inherit;
    font-weight: 400;
  }


  .timezone-picker-textfield .form-control{
    border-width: 0px;
    display: flex;
    justify-content: flex-start;
    padding: 0;
    font-size: 16px;
    font-family: inherit;
    font-weight: 400;
  }

  .custom-control.custom-switch{
    display: inline-block;
  }

  .sub-container{
    display: inline-block;
  }

  .add-league-header{
    margin-bottom: 5px;
    padding-bottom: 9px;
    border-bottom: 1px solid #e7e7e7;
  }

  .form-label{
    font-weight: bold;
  }