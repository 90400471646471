.App {
  margin-top: 15px;
}

.navbar{
  border: 1px solid #e7e7e7;
  border-radius: 4px;
} 

.nav-item{
  cursor: pointer;
}

/*
.App .navbar-brand {
  font-weight: bold;
}*/
