.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }

  .Home .lander .belowSignup {
    color: #999;
    font-size: x-small;
    margin-top: 5px;
  }

  .Home .lander .signUpButton {
    margin-top: 15px;
  }  

  .leagues h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    padding-bottom: 9px;
    border-bottom: 1px solid #e7e7e7;
  }
  
  .Home .leagues h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: inherit;
    font-size: 18px;
    cursor: pointer;
  }
  .Home .leagues p {
    color: #666;
  }